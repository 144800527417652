<template>
  <div id="archiv">
    <app-navbar></app-navbar>
    <div class="crossBallone">
      <img class="img-fluid" src="@/assets/crossRedBallone.png" alt="whiteBallone">
    </div>
    <div class="redBallone">
      <img class="img-fluid" src="@/assets/redBallone.png" alt="whiteBallone">
    </div>
    <div class="container archiveBlock">
      <h1 class="text-center">{{ "Archives.label" | staticTranslation }}</h1>
      <div class="row">
        <div class="col-lg-8 offset-lg-2 pb-lg-4">
          <div class="new-container">
            <a class="img-hover-zoom box-shadow-3d" href="/archiv/2022-final.pdf" download="/archiv/2022-final.pdf">
              <h4>2022</h4>
              <img class="img-fluid cover" src="@/assets/Archiv_2022.jpeg" alt="2022">
            </a>
          </div>
        </div>

        <div class="col-lg-8 offset-lg-2 pb-lg-4">
          <div class="new-container">
            <a class="img-hover-zoom box-shadow-3d" href="/archiv/2021-final.pdf" download="/archiv/2021-final.pdf">
              <h4>2021</h4>
              <img class="img-fluid cover" src="@/assets/Archiv_2021.jpeg" alt="2021">
            </a>
          </div>
        </div>

        <div class="col-lg-8 offset-lg-2 pb-lg-4">
          <div class="new-container">
            <a class="img-hover-zoom box-shadow-3d" href="/archiv/2020-final.pdf" download="/archiv/2020-final.pdf">
              <h4>2020</h4>
              <img class="img-fluid cover" src="@/assets/Archiv_2020.jpeg" alt="2020">
            </a>
          </div>
        </div>
        <div class="col-lg-8 offset-lg-2 pb-lg-4">
          <div class="new-container">
            <a class="img-hover-zoom box-shadow-3d" href="/archiv/2019-final.pdf" download="/archiv/2019-final.pdf">
              <h4>2019</h4>
              <img class="img-fluid cover" src="@/assets/Archiv_2019.jpeg" alt="2019">
            </a>
          </div>
        </div>
        <div class="col-lg-8 offset-lg-2 pb-lg-4">
          <div class="new-container">
            <a class="img-hover-zoom box-shadow-3d" href="/archiv/2018-final.pdf" download="/archiv/2018-final.pdf">
              <h4>2018</h4>
              <img class="img-fluid cover" src="@/assets/Archiv_2018.jpeg" alt="2018">
            </a>
          </div>
        </div>
        <div class="col-lg-8 offset-lg-2 pb-lg-4">
          <div class="new-container">
            <a class="img-hover-zoom box-shadow-3d" href="/archiv/2017-final.pdf" download="/archiv/2017-final.pdf">
              <h4>2017</h4>
              <img class="img-fluid cover" src="@/assets/Archiv_2017_1.jpeg" alt="2017">
            </a>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import footer from "@/components/base/footer";

export default {
  name: "Archiv",
  components: {
    'app-navbar': navbar,
    'app-footer': footer
  }
}
</script>

<style scoped>
.new-container {
  position: relative;
}

h1 {
  padding-top: 3.4em;
  padding-bottom: 1em;
  color: #152326;
  font-size: 2.5em;
  font-family: "Transcript Pro"

}

h2 {
  padding-top: 3.4em;
  padding-bottom: 1em;
  color: #152326;
  font-size: 2.5em;
  font-family: "Transcript Pro"
}

h3 {
  font-family: "Transcript Pro";
}

h4 {
  font-size: 1.5em;
  padding: 6px 6px 6px;
  background-color: #e7e7e6;
  font-family: 'Transcript Pro';
  position: absolute;
  color: #152326;
  text-align: left;
  bottom: 0;
  margin-bottom: 0;
  left: 0;
}
a {
  font-size: 1.3em;
  display: block;
  color: #152326;
  text-decoration: none;
  font-family: "Transcript Mono";
}

a:hover {
  color: #152326;
  text-decoration: underline;
  font-family: "Transcript Mono";
}

.archiveBlock {
  padding-bottom: 4em;
}

.crossBallone {
  width: 9%;
  position: absolute;
  left: 7%;
  top: 125vh;
}

.redBallone {
  width: 20%;
  position: absolute;
  text-align: end;
  right: 10%;
  top: 42%;
}

#archiv {
  background-color: #FFCE6D;
  height: 100vh;
}

.box-shadow-3d {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

.img-hover-zoom {
  height: 30vh;
  overflow: hidden;
  margin-bottom: 15px;
}

.img-hover-zoom img {
  transition: transform .5s ease;
}

.img-hover-zoom:hover img {
  transform: scale(1.3);
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
